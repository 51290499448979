import './App.css';
import React, { useEffect } from 'react';
import { Analytics } from 'aws-amplify';

import { CouponPageCollection, LandingPageCollection } from './ui-components';

import { Routes, Route } from "react-router-dom"





function App() {
  useEffect(() => {
    // Track an event with the device ID
    Analytics.record({
      name: 'appOpened',
      attributes: {
        deviceId: Analytics.getPluggable('AWSPinpoint')._config.endpointId,
      },
    });
  }, []);

  return (
    <div className="App">
 
        <Routes>
          <Route path="/" element={ <LandingPageCollection /> } />
          <Route path="Coupon" element={ <CouponPageCollection />} />
        
        
        </Routes>

      
    </div>
  );
}

export default App;


