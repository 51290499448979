/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_mobile_analytics_app_id": "218e2121070341c387e6b69ec92dc105",
    "aws_mobile_analytics_app_region": "us-east-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "218e2121070341c387e6b69ec92dc105",
            "region": "us-east-2"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://fgkhazynpnd2rgzlwfif7hyd7y.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-xmmrmveh3rf7denkbrjxggcf7q",
    "aws_cognito_identity_pool_id": "us-east-2:62fd9414-a0d6-41c6-9e47-263159d00c6c",
    "aws_cognito_region": "us-east-2",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": []
};


export default awsmobile;
