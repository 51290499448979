/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text, TextAreaField, View } from "@aws-amplify/ui-react";
export default function CouponPage(props) {
  const { offerBarCodeDB, overrides, ...rest } = props;
  return (
    <View
      width="390px"
      height="844px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "CouponPage")}
      {...rest}
    >
      <Flex
        gap="8px"
        direction="column"
        width="unset"
        height="113px"
        justifyContent="flex-start"
        alignItems="flex-start"
        position="absolute"
        top="509px"
        left="35px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Main Text")}
      >
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="700"
          color="rgba(13,26,38,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="320px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={offerBarCodeDB?.MFGCoupon}
          {...getOverrideProps(overrides, "Classic Long Sleeve T-Shirt")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="400"
          color="rgba(48,64,80,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.01px"
          width="320px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={offerBarCodeDB?.ExpDt}
          {...getOverrideProps(overrides, "Information about this product")}
        ></Text>
      </Flex>
      <TextAreaField
        width="319px"
        height="115px"
        position="absolute"
        top="600px"
        left="36px"
        label={offerBarCodeDB?.Rules}
        placeholder="Placeholder"
        size="default"
        isDisabled={false}
        labelHidden={false}
        variation="default"
        {...getOverrideProps(overrides, "TextAreaField")}
      ></TextAreaField>
      <Image
        width="320px"
        height="400px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="69px"
        left="calc(50% - 160px - 0px)"
        border="1px SOLID rgba(0,0,0,1)"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
        padding="0px 0px 0px 0px"
        objectFit="contain"
        src={offerBarCodeDB?.CouponImage}
        {...getOverrideProps(overrides, "image")}
      ></Image>
    </View>
  );
}
