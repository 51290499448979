/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  useNavigateAction,
} from "@aws-amplify/ui-react/internal";
import { Button, Image, View } from "@aws-amplify/ui-react";
export default function LandingPage(props) {
  const { offerBarCodeDB, overrides, ...rest } = props;
  const buttonOnClick = useNavigateAction({ type: "url", url: "/Coupon" });
  return (
    <View
      width="390px"
      height="844px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "LandingPage")}
      {...rest}
    >
      <Button
        width="322px"
        height="unset"
        position="absolute"
        top="536px"
        left="35px"
        size="large"
        isDisabled={false}
        variation="primary"
        children={offerBarCodeDB?.SaveOn}
        onClick={() => {
          buttonOnClick();
        }}
        {...getOverrideProps(overrides, "Button")}
      ></Button>
      <Image
        width="320px"
        height="400px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="66px"
        left="calc(50% - 160px - 0px)"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
        padding="0px 0px 0px 0px"
        objectFit="cover"
        src={offerBarCodeDB?.ProductImage}
        {...getOverrideProps(overrides, "image")}
      ></Image>
    </View>
  );
}
